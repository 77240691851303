import Vue from "vue";
import App from "./App.vue";
import router from "./router/router.js";
import axios from "axios";
import BaiduMap from "vue-baidu-map";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import VueAnimateNumber from "vue-animate-number";
import VueAwesomeSwiper from "vue-awesome-swiper";
import "@/styles/reset.css"
import "swiper/css";
import store from "@/store/index.js";
import HTTP from "@/utils/request.js";
import Vant from 'vant'
import 'vant/lib/index.css'

Vue.prototype.$http = HTTP;
Vue.use(VueAwesomeSwiper);
Vue.use(VueAnimateNumber);
Vue.use(ElementUI);
Vue.use(BaiduMap, {
  // ak 是在百度地图开发者平台申请的密钥 详见 http://lbsyun.baidu.com/apiconsole/key */
  ak: "Q8tv4t30UsFU8a47fRX3EwbiGPNvhODT",
});
Vue.use(Vant)
//
import VueLazyload from "vue-lazyload";
Vue.use(VueLazyload, {
  //完全显示的时候加载
  preLoad: 1,
  //失败时显示的图片
  error: require("@/assets/img/error.png"),
  //加载时显示的GIF图
  loading: require("@/assets/img/loading.gif"),
  //尝试加载几次
  attempt: 1,
});

Vue.config.productionTip = false;
//全局定义axios
Vue.prototype.$http = axios;

// 图片服务器地址
// Vue.prototype.imgserver = "";

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
