import service from "@/utils/request.js"
import QS from 'qs'

export const getContent = () => {
 return service.get("/api/ay/content-sort")
}

export const getContentDetail = (id) => {
 return service.get(`/api/ay-content-sort/${id}`)
}

export function getMetaverse(params) {
 return service.get("/api/ay-vr", { params });
}

export function contract(id) {
    return service.get(`/api/contract-specification/${id}`);
}

export function getGuestId(password) {
    return service.get(`/api/login?${QS.stringify(password)}`);
}
