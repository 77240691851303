import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

let kejianrouter = new Router({

  mode:'history',
  routes: [{
    path: '/',
    name: 'home',
    component: () => import('@/views/Home/Home.vue'),
    meta: { title: "首页" },
  },
  {
    path: '/newsdetails',
    name: 'newsdetails',
    meta: { title: "新闻详情" },
    component: () => import('@/views/NewsDetails.vue')
  },
  {
    meta: { title: "工业元宇宙" },
    path: '/metaverse',
    name: 'metaverse',
    component: () => import('@/views/metaverse.vue'),
  },
  {
    meta: { title: "工业元宇宙" },
    path: '/metaverseMobile',
    name: 'metaverseMobile',
    component: () => import('@/views/metaverseMobile.vue'),
  },
  {
    meta: { title: "图片预览" },
    path: '/imgPreview',
    name: 'imgPreview',
    component: () => import('@/views/Home/imgPreview.vue')
  },
  // {
  //   meta: { title: "新闻详情" },
  //   path: '/newsdetails',
  //   name: 'newsdetails',
  //   component: () => import('@/views/NewsDetails.vue'),
  // },
  // {
  //   path: '/live',
  //   name: 'product',
  //   meta: { title: "快直播" },

  //   component: () => import('@/views/live.vue'),
  // },
  // {
  //   path: '/case',
  //   name: 'case',
  //   meta: { title: "服务范围" },

  //   component: () => import('@/views/Case.vue')
  // },
  // {
  //   path: '/casedetails/:id',
  //   name: 'casedetails',
  //   component: () => import('@/views/CaseDetails.vue')
  // },
  // {
  //   path: '/goin',
  //   name: 'goin',
  //   meta: { title: "关于快车" },

  //   component: () => import('@/views/GoIn.vue')
  // },
  // {
  //   path: '/download',
  //   name: 'download',
  //   component: () => import('@/views/Download.vue')
  // },
  {
    path: '/us',
    name: 'us',
    meta: { title: "联系我们" },

    component: () => import('@/views/us/us.vue'),
    redirect: "/us/list",
    children: [{
      path: '/us/list',
      name: 'list',
      meta: { title: "联系我们" },

      component: () => import('@/views/us/list.vue')
    },
    {
      path: '/us/details',
      name: 'details',
      meta: { title: "人才招聘" },

      component: () => import('@/views/us/details.vue')
    },
    ]
  },
  {
    path: '/contractSpecification/:id',
    name: 'contractSpecification',
    meta: { title: '合同详情' },
    component: () => import('../views/Contract/specification.vue'),

  },
  ]
})

// // 判断是否需要登录权限 以及是否登录
kejianrouter.beforeEach((to, from, next) => {
 if(to.meta.title){
    //判断是否有标题
    document.title = to.meta.title+'-广东快车科技股份有限公司'
  }

  next() 
})

export default kejianrouter